<template>
  <v-col>
    <v-card class="ma-5 pa-5">
      <h6>PROJEKTKÜRZEL</h6>
      <div class="cardText">{{ project.code }}</div>
      <h6>PROJEKTLEITUNG</h6>
      <div class="cardText">{{ project.project_lead }}</div>
      <h6>LAND</h6>
      <div class="cardText">{{ project.country }}</div>
      <h6>REGION</h6>
      <div class="cardText">{{ project.region }}</div>
      <h6>ZIELGRUPPE</h6>
      <div class="cardText">{{ project.target_group }}</div>
      <h6>PROJEKTSTATUS</h6>
      <div class="cardText">{{ project.project_state }}</div>
      <h6>REGIONALGRUPPE</h6>
      <div class="cardText" v-if="project.section">{{ project.section.name }}</div>
    </v-card>
    <v-card class="ma-5 pa-5">
      <h6>KONTAKT</h6>
      <h6>Ingenieur:innen ohne Grenzen Austria</h6>
      <div class="cardText" v-if="project.section">{{ project.section.mail }}</div>
      <h6>Projektleitung</h6>
      <div class="cardText">{{ project.project_lead }}</div>
    </v-card>
    <v-card class="ma-5 pa-5">
      <h6>UNTERSTÜTZT VON</h6>
      <h6>Projektpartner:innen</h6>
      <div class="cardText">{{ project.partner }}</div>
      <h6 v-if="project.sponsors">Sponsor:innen</h6>
      <div class="cardText" v-if="project.sponsors">{{ project.sponsors }}</div>
      <h6 v-if="project.funding">F&ouml;rderstellen</h6>
      <div class="cardText" v-if="project.funding">{{ project.funding }}</div>
    </v-card>
  </v-col>
</template>
<script>
export default {
  name: 'project-codes',
  props: {
    project: {}
  }
}
</script>
<style scoped>

h6 {
  color: var(--v-primary-base);
  font-size: 10px;
  padding-top: 8px;
}

.cardText {
  font-size: 16px;
  padding-bottom: 8px;
}

</style>